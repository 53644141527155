<template>
  <div class="page">
    <div class="page_body">
      <div class="input_zone mt100">
        <van-cell-group>
          <van-field
            v-validate="'required|numeric|length:11|max:11|mobile'"
            v-model="celPhone"
            name="celPhone"
            type="text"
            :error-message="errors.first('celPhone')"
            placeholder="请输入手机号"
          />
        </van-cell-group>

        <van-cell-group class="mt10">
          <van-field
            v-validate="'required|numeric|length:4|max:4'"
            v-model="captcha"
            name="captcha"
            type="text"
            :error-message="errors.first('captcha')"
            placeholder="请输入验证码"
          >
            <CaptchaBtn
              slot="button"
              :phone="celPhone"
              scene="bindphone"
              @click="sendcode"
              ref="CaptchaBtn"
            />
          </van-field>
        </van-cell-group>
      </div>

      <div class="mt50 ml20 mr20">
        <PinkBtn text="确定" @click="validate"/>
      </div>
    </div>

    <div class="copyright tc">&copy; 淘街坊 - 发现身边优惠</div>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import PinkBtn from "@/components/PinkBtn";
import CaptchaBtn from "@/components/CaptchaBtn";
import { Field, Button, CellGroup } from "vant";
import { wxBindPhone } from "@/api/user";
import ls from "@/libs/localstore";

Vue.use(Field)
  .use(Button)
  .use(CellGroup);

require("@/libs/validate.js");

export default {
  name: "BindPhone",
  components: {
    StatusBar,
    NavBar,
    PinkBtn,
    CaptchaBtn
  },
  data() {
    return {
      img: {
        close_btn: require("@/assets/common/close_btn.png")
      },
      celPhone: null,
      captcha: null
    };
  },
  mounted: function() {},
  methods: {
    validate: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.bind();
        } else {
          // this.$toast("请按提示正确填写所有信息");
        }
      });
    },
    sendcode: function() {
      this.$validator.validate("celPhone", this.celPhone).then(result => {
        if (result) {
          this.$refs.CaptchaBtn.send();
        }
      });
    },
    bind: function() {
      let params = {
        phone: this.celPhone,
				code: this.captcha,
				wx_id: this.$route.query.wx_id
      };

      wxBindPhone(params)
        .then(res => {
          if (res.errCode == 0) {
            this.$toast.success("绑定成功");
            // 绑定成功后的业务
						// this.$router.push("/personal");
						this.loginSuccessCallback(res.data.token, res.data.userInfo)
          } else if(res.errCode == 'ERR_02233') {
						// 更新token
						ls.setTmpToken(res.data.token);
						// 更新个人信息
						ls.setUserInfo(res.data.userInfo);

						this.$router.push({
							path: '/invite-input'
						})
					} else {
            this.$toast(res.errMsg);
          }
        })
        .catch(err => {
          this.$toast(err.errMsg);
          // 做些错误处理
        });
		},
		loginSuccessCallback: function(token, userInfo) {
      this.$cookie.set("user-token", token, { expires: "2h" });
      // 更新token
      ls.setToken(token);
      // 更新个人信息
      ls.setUserInfo(userInfo);
      // 获取登录前的页面
      let url = this.$cookie.get("beforeLoginUrl");
      // 若是url不存在或者是授权页面则跳到首页
      if (!url || url.indexOf("/login") != -1) {
        this.$router.push("/");
      } else {
        // 清空这个cookie
        this.$cookie.set("beforeLoginUrl", "");
        // 跳转至开始的目标页面
        this.$router.push(url);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  background: #fff;
}
.input_zone {
  padding: 0 30px;
}
.findpwd {
  color: #4a4a4a;
}
.info_zone {
  font-size: 16px;
  color: #4a4a4a;
}
.info_zone a {
  color: #4a4a4a;
}
.van-cell {
  font-size: 16px;
}
[class*="van-hairline"]::after {
  border-top-color: transparent !important;
}
</style>
